import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'
export const SmallImgContentblkStyles = styled.div`
  .row {
    margin-top: 74px;
    display: flex;
    justify-content: space-between;
    ${xl(`
      margin-top: 30px;
    `)}
     ${lg(`
        margin-top: 40px;
        flex-direction: column;
    `)}
    ${md(`
        margin-top: 20px;
    `)}
    .col {
      flex: 0 1 540px;
      &:first-child {
       ${xl(`
        flex: 0 0 380px;
      `)}
      ${lg(`
          flex: 1 1 auto;
          max-width:380px;
          margin-bottom:40px; 
      `)}
      ${md(`
          margin-bottom:10px; 
      `)}
      }
      &:last-child {
        ${xl(`
          flex: 1 1 auto;
          padding-left:40px;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
      figure {
        margin: 0;
        margin-top: -15px;
        margin-left: -17px;
        ${xl(`
          margin-top: 0px;
          margin-left: -10px;
      `)}
      }
    }
  }
`
