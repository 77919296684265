import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'

export const CaseproductfeaturesecStyles = styled.div`
 margin-bottom: 120px; 
  ${xl(`
      margin-bottom: 90px;
  `)}
  ${lg(`
    margin-bottom: 60px; 
  `)}
  ${md(`
      margin-bottom: 40px; 
  `)} 
  .heading {
    text-align:center;
  }
  .featureTopImg {
    figure {
      margin:73px 0 120px;
      ${xl(`
        margin:70px 0 90px;
      `)}
      ${lg(`
        margin:50px 0 60px;
      `)}
      ${md(`
          margin:40px 0;
      `)} 
    }
  }
  .secBottomImg {
    figure {
      margin:65px 0 76px; 
      ${lg(`
        margin:60px 0 60px;
      `)}
      ${md(`
          margin:40px 0;
      `)} 
    }
  }
  .featureContentRow {
    display:flex;
    margin:0 -30px; 
    ${lg(`
        margin:0 -15px;
      `)}
      ${md(`
       flex-direction: column;
      `)} 
    .featureContentCol {
      padding:0 30px;
      flex:1;
      ${lg(`
        padding:0 15px;
      `)}
    }
  }
`
