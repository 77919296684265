import styled from 'styled-components'
import { xl, lg, md, xll } from 'config/variables'

export const PaymentgatewaysecStyles = styled.div`
  padding-bottom: 67px; 
  position: relative;
  margin-bottom: 120px;  
  ${xl(`   
      margin-bottom: 100px; 
  `)}
  ${lg(`
    margin-bottom: 60px; 
    padding-bottom: 0; 
  `)}
  ${md(`
      margin-bottom: 40px; 
  `)}
  .paymentgatewayRow {
    display: flex;
    ${lg(`
      flex-direction: column;
    `)}
    .paymentDetailsCol {
      flex: 1 1 844px;
      padding: 107px 250px 80px 149px;
      background: #142f6c;
      ${xll(`
          padding: 80px 250px 40px 60px;
      `)}
      ${xl(`
          padding: 50px 250px 40px 40px;
      `)}
      ${lg(`
          padding: 91px 40px 40px 40px;
          order:2;
          flex:1 1 auto
      `)}
      ${md(`
          padding: 91px 20px 30px 20px;
          order:2;
          flex:1 1 auto
      `)}
      h2 {
        color: #fff;
      }
      p {
        color: #fff;
        padding-right: 0;
        font-size: 22px;
        line-height: 34.8px;
        letter-spacing: -0.15px;
        ${lg(`
          padding-right: 0;
      `)}
      }
    }
    .paymentImgCol {
      flex: 1 1 599px;
      position: relative;
      display: flex;
      flex-direction: column;
      /* min-height: 844px; */
      ${lg(`
        order:1;
        min-height: auto;
        height:400px;
        flex:1 1 auto
      `)}
      .bgImg {
        height: 100%;
        display: flex;
        flex-direction: column;
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
      .paymentMockupImg {
        position: absolute;
        bottom: -67px;
        left: -263px;
        width: 410px;
        ${lg(`
          left: 0; 
          margin:0;
          width:280px;
        `)}
      }
    }
  }
`
