import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { ftsProps } from 'components/Props/food-truck-spaces'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md, sm, xll } from 'config/variables'
import Locaranimationbanner from 'components/Locaranimationbanner/Locaranimationbanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Engagementsec from 'components/Engagementsec/Engagementsec'
import Paymentgatewaysec from 'components/Paymentgatewaysec/Paymentgatewaysec'
import SmallImgContentblk from 'components/SmallImgContentblk/SmallImgContentblk'
import Caseappadvantagesec from 'components/Caseappadvantagesec/Caseappadvantagesec'
import Caseuiexperiencesec from 'components/Caseuiexperiencesec/Caseuiexperiencesec'
import Caseproductfeaturesec from 'components/Caseproductfeaturesec/Caseproductfeaturesec'
import Mobileappdrivercontrol from 'components/Mobileappdrivercontrol/Mobileappdrivercontrol'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const FoodTruckSpacespage = styled.div`
.caseAnimationBanner {
  margin-bottom:40px;
  ${lg(`
    margin-bottom:0;
  `)} 
  .img-block { 
    margin-top:30px;
    ${sm(`
        overflow: hidden;
      `)}
    ${md(`
        margin-top:0px;
      `)}
    .bannerImage {
      width: 633px;
      position: relative;
      height:469px;
      ${xl(`
        width: 460px;
        height:350px;
      `)}
      ${lg(`
        width: 360px;
        height:310px;
      `)}
      ${sm(`
        width: 260px;
        height: 238px;
      `)}
      img {
        position:absolute;
        &.ftsCircleImg {
          right: 12px;
          top: 63px;
          z-index:1;
          animation: rotation 15s infinite linear;
          transform-origin:center;
          ${xl(`
            right: 0;
            top: 42px;
            max-width: 130px;
          `)}
          ${lg(`
            right: 0;
            top: 63px;
            max-width: 100px;
          `)}
          ${sm(`
            right: -8px;
            top: 47px;
            max-width: 100px;
          `)}
        }
        &.ftsTruckImg {
          right: 0px;
          bottom: 6px;
          z-index: 9;
          max-width: 571px;
          ${xl(`
            max-width:420px;
          `)}
          ${lg(`
            max-width:350px;
          `)}
          ${sm(`
            max-width:260px;
          `)}
        }
        
        &.ftsFoodImg1 {
          top: -18px;
          left: 88px;
          z-index: 9;
          transform: translatey(0px);
          animation: float 7s ease-in-out infinite; 
          ${xl(` 
            top: -18px;
            left: 88px;
            max-width: 120px;
          `)}
          ${lg(`
            top: 10px;
            left: 30px;
            max-width: 90px;
          `)}
          ${sm(`
             top: 10px;
            left: 30px;
            max-width: 80px;
          `)}
        }  
        &.ftsFoodImg2 {
          top: 60px;
          left: 0;
          z-index: 9;
          transform: translatex(0px) scale(0.3);
          animation: floatx 8s ease-in-out infinite;
          ${xl(`
            top: 30px;
            left: 0;
          `)}
          ${lg(`
            top: 50px;
            left: 0;
            max-width: 100px;
          `)}
          ${sm(`
            top: 50px;
            left: 0;
            max-width: 80px;
          `)}
        } 
        &.ftsFoodImg3 {
          bottom: 120px;
          left: -9px;
          z-index: 2; 
          transform: translatex(0px) scale(0.3);
          animation: floatx 8s ease-in-out infinite;
          ${xl(`
              bottom: 83px;
              left: -9px;
              max-width: 140px;
          `)}
          ${lg(`
              bottom: 77px;
              left: -4px;
              max-width: 100px;
          `)}
          ${sm(`
              bottom: 60px;
              left: 0;
              max-width: 80px;
          `)}
        }
        &.ftsFoodImg4 {
          bottom: 25px;
          right: -13px;
          z-index: 9;
          transform: translatey(0px);
          animation: float 7s ease-in-out infinite;
          ${xl(`
           bottom: 25px;
           right: -13px;
           max-width:150px
          `)}
          ${lg(`
            bottom: 25px;
            right: -13px;
            max-width: 120px;
          `)}
          ${sm(`
            bottom: 25px;
            right: -13px;
            max-width: 100px;
          `)}
        }
      }
    }
  }
}
.engagementSec {
  .fihmainscreen {
    margin-top:72px;
    ${xl(`
       margin-top:70px;
    `)}
    ${lg(`
      margin-top:30px;
    `)}
    ${md(`
        margin-top:0;
    `)} 
  }
} 
.paymentGatewaySec {
 .paymentgatewayRow {
    .paymentDetailsCol {
      background-image: linear-gradient(135deg, #e44021 -25%, #531207 87%);
    }
    .paymentImgCol {
      .paymentMockupImg {
        width:793px;
        left: -200px;
        ${xll(`
          width: 683px;
          left: -220px;
          bottom: -60px;
        `)}
        ${xl(`
          width: 583px;
          left: -220px;
          bottom: -40px;
        `)}
        ${lg(`
          width: 713px;
          left: 0;
          bottom: -40px;
        `)}
        ${md(`
         width: 410px;
          left: 0;
          bottom: -50px;
        `)}
        ${sm(`
          width: 300px;
          left: 0;
          bottom: -50px;
        `)}
      }
    } 
  }
}
.smallImgContentblk {
  margin-bottom: 120px; 
    ${xl(`
        margin-bottom: 90px;
    `)}
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
        margin-bottom: 40px; 
    `)} 
}
.MobileappdrivercontrolSec  {
  margin-bottom: 120px; 
    ${xl(`
        margin-bottom: 90px;
    `)}
    ${lg(`
      margin-bottom: 60px; 
    `)}
    ${md(`
        margin-bottom: 40px; 
    `)} 
    .mobileAppsheading {
      h4 {
        background: #fff;
        z-index: 1;
        top:2px;
        position: relative;
        display: inline-block;
      }
    }
  .mobileAppsContent {
    padding-bottom:0px;
    .mobileAppsContentLeft {
      .mobileAppsTitle{
        padding-bottom:0px;
        &::before {
          display:none;
        }
      }
    }
  }
}
`
const FoodTruckSpaces = props => {
  const { data } = props
  const { location } = props

  let locarPaymnetImg = [props.data.locarPaymnetImg.childImageSharp.fluid]
  let locarMockupImg = [props.data.locarMockupImg.childImageSharp.fluid]

  let leftScreenItem = [
    props.data.ftsUiScreen1.childImageSharp.fluid,
    props.data.ftsUiScreen2.childImageSharp.fluid,
    props.data.ftsUiScreen3.childImageSharp.fluid,
  ]
  let rightScreenItem = [
    props.data.ftsUiScreen4.childImageSharp.fluid,
    props.data.ftsUiScreen5.childImageSharp.fluid,
    props.data.ftsUiScreen6.childImageSharp.fluid,
  ]

  let daigramImg = [props.data.productFeatureDaigram.childImageSharp.fluid]
  let truckImg = [props.data.productTruckImg.childImageSharp.fluid]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Food Truck Spaces"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <FoodTruckSpacespage>
        <Locaranimationbanner bannerdata={ftsProps} />
        <Caseintroduction
          caseintrodata={ftsProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Engagementsec
            engagementsecdata={ftsProps}
            {...props}
            showFullimages={false}
          />
        </div>

        {showContent && (
          <>
            <Paymentgatewaysec
              paymentGatewayData={ftsProps}
              paymnetImg={locarPaymnetImg}
              mockupImg={locarMockupImg}
              {...props}
            />
            <SmallImgContentblk
              smallImgcontentdata={ftsProps}
              {...props}
              showFullimages={false}
            />
            <Caseappadvantagesec caseAppAdvantageData={ftsProps} />
            <Caseuiexperiencesec
              uiexperienceData={ftsProps}
              leftScreenList={leftScreenItem}
              rightScreenList={rightScreenItem}
            />
            <Caseproductfeaturesec
              productFeatureData={ftsProps}
              secTopImg={daigramImg}
              secBottomImg={truckImg}
            />
            <Mobileappdrivercontrol
              Mobileappdrivercontroldata={ftsProps}
              {...props}
            />
            <Relatedcaselist relatedcaselistdata={ftsProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={ftsProps} />
          </>
        )}
      </FoodTruckSpacespage>
    </Layout>
  )
}

export default FoodTruckSpaces

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/fts-frontcard-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/craypay-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1095) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fihmainscreen: file(
      relativePath: { regex: "/fts-challenges-img1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 981) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarPaymnetImg: file(relativePath: { regex: "/fts-result-img@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    locarMockupImg: file(
      relativePath: { regex: "/fts-result-mockup@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 793) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    scalefusion: file(relativePath: { regex: "/scalefusion@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 553) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ftsUiScreen1: file(relativePath: { regex: "/fts-ui-screens1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 583) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ftsUiScreen2: file(relativePath: { regex: "/fts-ui-screens2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 583) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ftsUiScreen3: file(relativePath: { regex: "/fts-ui-screens3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 583) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ftsUiScreen4: file(relativePath: { regex: "/fts-ui-screens4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 583) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ftsUiScreen5: file(relativePath: { regex: "/fts-ui-screens5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 583) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ftsUiScreen6: file(relativePath: { regex: "/fts-ui-screens6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 583) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    productFeatureDaigram: file(
      relativePath: { regex: "/fts-aws-services-daigrams@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    productTruckImg: file(
      relativePath: { regex: "/fts-product-trucks-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/sentmap-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/craypay-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
