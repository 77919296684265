import React from 'react'
import { images } from 'config/images'

export const ftsProps = {
  locarBannerProps: {
    heading: 'FoodTruck Spaces',
    bannerPara:
      'Bringing Food Trucks and Spaces together to revolutionize $2 billion Food Industry',
    BannerAlt: 'FoodTruck Spaces Case Study',
    locarBannerImage: [
      {
        className: 'ftsCircleImg',
        imgUrl: images.ftsBannerCircle,
      },
      {
        className: 'ftsTruckImg',
        imgUrl: images.ftsBannerTruckImg,
      },
      {
        className: 'ftsFoodImg1',
        imgUrl: images.ftsBannerFoodImg1,
      },
      {
        className: 'ftsFoodImg2',
        imgUrl: images.ftsBannerFoodImg2,
      },
      {
        className: 'ftsFoodImg3',
        imgUrl: images.ftsBannerFoodImg3,
      },
      {
        className: 'ftsFoodImg4',
        imgUrl: images.ftsBannerFoodImg4,
      },
    ],
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Mobility is the greatest asset in the food truck business, where your
        ability to make profits directly leans on your ability to be
        consistently present at crowd pouring locations. The unique nature of
        Food Truck Business attracts thousands of food entrepreneurs to enter
        the industry each year. <br />
        <br />
        However, as the industry grows and attracts new food entrepreneurs, the
        venture comes with its own set of challenges, ranging from searching for
        locations, acquiring permits, contacting owners, etc. To streamline this
        process, FoodTruckSpaces is using tech to connect Food truck owners with
        property owners, the two entities of the food truck industry.
      </>
    ),
  },
  EngagementsecProps: {
    Title: 'challenges',
    subTitle: (
      <>Closing the gap between Food Truck Entrepreneurs and Space Owners</>
    ),
    para: (
      <>
        The age-old practice of renting spaces requires Food Entrepreneurs to
        search for opportunities from the internet, newspapers, or billboards
        manually. Moreso, finding properties, contacting property owners, and
        managing burdensome paper regulations; leveraging business opportunities
        in the food industry comes with its unique set of challenges. <br />{' '}
        <br />
        Since the entire process was mainly manual, FTS wanted to develop a tech
        solution, which can act as an automated directory between event
        organizers, property owners, and food entrepreneurs.
      </>
    ),
    imgData: [
      {
        imgUrl2x: images.ftsChallengesImg12x,
        imgUrl: images.ftsChallengesImg1,
      },
      {
        imgUrl2x: images.ftsChallengesImg22x,
        imgUrl: images.ftsChallengesImg2,
      },
      {
        imgUrl2x: images.ftsChallengesImg32x,
        imgUrl: images.ftsChallengesImg3,
      },
    ],
  },

  paymentGatewayProps: {
    Title: 'Result',
    para: (
      <>
        After analyzing the business model, we designed and developed a Single
        page application using AngularJS where food truck owners can pre-book
        food truck spaces. It eliminated the manual process of juggling between
        spreadsheets, calendars, phone calls, and payments for booking food
        truck rental spaces.
        <br />
        <br />
        Following the launch of FoodTruckSpaces, food entrepreneurs saw a 30%
        jump in revenues. Furthermore, the properties are now making more money
        by renting out to different food truck owners for different time slots.
      </>
    ),
  },
  smallImgcontentProps: {
    heading: 'Building a Powerful Enterprise Web Platform',
    para: (
      <>
        For food entrepreneurs to search and book spaces, property owners to
        advertise their location, and the food community to find exciting food
        joints, we had to develop a solution that can respond with ease multiple
        times a day, every day. Hence, Rather than going with the traditional
        multi-page application, we went for a single page web app. <br />
        <br />
        <strong>Single Page Web Apps</strong> (SPA) are web apps which interact
        with users by automatically rewriting current pages, rather than
        reloading new ones from the server.
      </>
    ),
  },
  caseAppAdvantageProps: {
    heading: (
      <>
        Advantages of Single Page <br />
        Web App to FoodTruckSpaces
      </>
    ),
    caseAppAdvantageRow: [
      {
        title: 'Fast and Responsive web application',
        iconUrl: images.ftsResponsiveWebIcon,
        para:
          'It’s a loss of business if a web app takes more than 200 milliseconds to respond. Since SPA does not reload the entire page when a user interacts with any given content, the speed at which FTS can interact with its users is par excellence.',
      },
      {
        title: 'Offline Functioning Capabilities',
        iconUrl: images.ftsOfflineIcon,
        para:
          'SPA’s caching capabilities allow it to store all the data by sending only one request to the server, making it useful for FTS to use this data to work seamlessly even with low or no internet connectivity.',
      },
      {
        title: 'Easy and fast development and deployment',
        iconUrl: images.ftsDevDepIcon,
        para:
          'SPA is a mix of only one HTML, CSS, and Javascript static file, which gave us a massive advantage in terms of speed and efficiency during the development process.',
      },
    ],
  },
  uiexperienceProps: {
    heading:
      'Creating seamless UI and highly perfromant platform using AngularJS and C# & .Net',
    para: (
      <>
        Since application logic moves from backend to the browser in a SPA, we
        needed a much superior client side interface. Hence, we used the
        combination of ASP.Net Web API and AngularJS which is widely favoured
        for providing a rich application structure. <br /> <br />
        AngularJS allowed secure data binding, giving our developers the ability
        to separate the controller from the code. The division provided users a
        seamless and highly responsive UI experience at all times. AngularJS
        also empowered our developers with dependency injections, which made
        controlling and testing FTS extremely fast. <br /> <br />
        We wrote APIs with C# which interfaces with SQL server 2017 database
        running on Amazon RDS instance. RDS took care of all the administration
        work like setup, backup, patching, etc.
      </>
    ),
    leftColHead: 'For Food Trucks',
    rightColHead: 'For Properties',
  },
  productFeatureProps: {
    heading: 'Leveraging AWS services to engineer the core product features',
    imgCaption:
      'Achieving speed and ease of use with a high performant architecture',
    featureContentRow: [
      {
        rowHead:
          'Using Lambda Function and Vonage to automate confirmations, cancellations etc via SMS',

        rowPara: (
          <>
            FTS enabled one of the essential features of the mobile food
            industry - i.e., booking food spaces and managing transactions
            online. To add to the functionality and better manage records, we
            needed to notify property and truck owners on various occasions like
            booking and payment confirmations, cancellations, etc. <br />
            <br />
            Hence, our developer made use of Lambda function, an event-driven
            serverless computing platform, alongside Vonage, a communication API
            for sending SMS programatically.
          </>
        ),
      },
      {
        rowHead: 'Uploading and securing documents with AWS S3',
        rowPara: (
          <>
            Navigating regulations, acquiring permits, complying with property
            owners is a burdensome paper process, which can take days at a
            stretch for food entrepreneurs to rent spaces. To mellow the
            process, we introduced a document upload feature, which eased the
            documentation process. <br />
            <br />
            To secure the documents, we used Amazon Simple Storage Service (S3),
            which protected the records from unauthorized access with unique
            encryption features.
          </>
        ),
      },
    ],
    bottomContentHead:
      'Managing payments, cancellation, refunds using Authorize.net payment gateway',
    bottomContentPara: (
      <>
        Since FTS offered to book food spaces online, it was crucial to
        facilitate a booking process. Therefore, we added a wallet feature in
        the app to process booking fees for renting properties. Property fees
        get transferred in the account of the property owners. FTS deducts a
        pre-informed service fee from every booking made through the platform.
        In case of late cancellations, FTS deducts a percentage of the booking
        cost and refunds the remaining amount in the truck owner's wallet, which
        they can use for future transactions. To streamline the booking process,
        we turned to Authorise.net to provide a glitch-free payment gateway.
      </>
    ),
  },
  MobileappdrivercontrolProps: {
    Title: 'Marketing',
    subTitle: (
      <>Giving full control to manage public facing website using Wordpress </>
    ),
    para: (
      <>
        To give control in the hands of the founders, we developed the marketing
        site on Wordpress. Wordpress gives full access to the content to the
        product owners, who can make changes in the content or the layout of the
        website, all by themselves.
      </>
    ),
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'SentMap',
        para: 'Sentiment based market analytics engine for portfolio managers',
        className: 'pale-blue',
        link: '/sentiment-market-analytics-engine/',
      },
      {
        title: 'CrayPay',
        para: 'Making M-Payment a profitable payment option in retail stores',
        className: 'mint',
        link: 'case-studies/cray-pay/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
