import React from 'react'

import { CaseproductfeaturesecStyles } from './Caseproductfeaturesec.styles'
import Image from 'components/ImgOptimized'

function Caseproductfeaturesec(props) {
  const {
    productFeatureData: { productFeatureProps },
  } = props
  return (
    <CaseproductfeaturesecStyles>
      <div className="container">
        <div className="heading">
          <h2>{productFeatureProps.heading}</h2>
        </div>
        <div className="featureTopImg">
          <figure>
            <Image
              fluid={props.secTopImg}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 694,
              }}
            />
            <div className="img-caption">{productFeatureProps.imgCaption}</div>
          </figure>
        </div>
        <div className="featureContentRow">
          {productFeatureProps.featureContentRow.map(
            (featureContentProp, i) => {
              return (
                <div key={i} className="featureContentCol">
                  <h3>{featureContentProp.rowHead}</h3>
                  <p>{featureContentProp.rowPara}</p>
                </div>
              )
            }
          )}
        </div>
        <div className="secBottomImg">
          <figure>
            <Image
              fluid={props.secBottomImg}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 694,
              }}
            />
          </figure>
        </div>
        <div className="fullContentRow">
          <h3>{productFeatureProps.bottomContentHead}</h3>
          <p>{productFeatureProps.bottomContentPara}</p>
        </div>
      </div>
    </CaseproductfeaturesecStyles>
  )
}

export default Caseproductfeaturesec
