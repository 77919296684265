import React from 'react'
import { PaymentgatewaysecStyles } from './Paymentgatewaysec.styles'
import Image from 'components/ImgOptimized'

function Paymentgatewaysec(props) {
  const {
    paymentGatewayData: { paymentGatewayProps },
  } = props
  return (
    <PaymentgatewaysecStyles className="paymentGatewaySec">
      <div className="paymentgatewayRow">
        <div className="paymentDetailsCol">
          <h2>{paymentGatewayProps.Title}</h2>
          <p>{paymentGatewayProps.para}</p>
        </div>
        <div className="paymentImgCol">
          <div className="bgImg">
            <Image
              fluid={props.paymnetImg}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 694,
              }}
            />
          </div>
          <div className="paymentMockupImg">
            <Image
              fluid={props.mockupImg}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 694,
              }}
            />
          </div>
        </div>
      </div>
    </PaymentgatewaysecStyles>
  )
}

export default Paymentgatewaysec
