import React from 'react'
import { CaseuiexperiencesecStyles } from './Caseuiexperiencesec.styles'
import Image from 'components/ImgOptimized'

function Caseuiexperiencesec(props) {
  const {
    uiexperienceData: { uiexperienceProps },
  } = props

  return (
    <CaseuiexperiencesecStyles>
      <div className="container">
        <div className="heading">
          <h2>{uiexperienceProps.heading}</h2>
          <p>{uiexperienceProps.para}</p>
        </div>
        <div className="uiScreenRow">
          <div className="uiScreenLeftCol">
            <div className="uiScreenHead">
              <h4>{uiexperienceProps.leftColHead}</h4>
            </div>
            <div className="screenList">
              {props.leftScreenList.map((screenImgItem, i) => {
                return (
                  <div key={i} className="screenListItem">
                    <Image
                      fluid={screenImgItem}
                      lazyload={{
                        enabled: true,
                        once: true,
                        offset: 1000,
                        heigth: 492,
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="uiScreenRightCol">
            <div className="uiScreenHead">
              <h4>{uiexperienceProps.rightColHead}</h4>
            </div>
            <div className="screenList">
              {props.rightScreenList.map((screenImgItem, i) => {
                return (
                  <div key={i} className="screenListItem">
                    <Image
                      fluid={screenImgItem}
                      lazyload={{
                        enabled: true,
                        once: true,
                        offset: 1000,
                        heigth: 492,
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </CaseuiexperiencesecStyles>
  )
}

export default Caseuiexperiencesec
