import React from 'react'
import { CaseappadvantagesecStyles } from './Caseappadvantagesec.styles'

function Caseappadvantagesec(props) {
  const {
    caseAppAdvantageData: { caseAppAdvantageProps },
  } = props
  return (
    <CaseappadvantagesecStyles>
      <div className="container">
        <div className="heading">
          <h2>{caseAppAdvantageProps.heading}</h2>
        </div>
        <div className="advantageContentRow">
          {caseAppAdvantageProps.caseAppAdvantageRow.map(
            (caseAppAdvantageProp, i) => {
              return (
                <div key={i} className="advantageContentCol">
                  <div className="advantageContentBox">
                    <div className="iconThumb">
                      <img src={caseAppAdvantageProp.iconUrl} alt="" />
                    </div>
                    <h4>{caseAppAdvantageProp.title}</h4>
                    <p>{caseAppAdvantageProp.para}</p>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    </CaseappadvantagesecStyles>
  )
}

export default Caseappadvantagesec
