import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'

export const CaseuiexperiencesecStyles = styled.div`
 margin-bottom: 120px; 
 padding-bottom:86px;
 position: relative;
 ${xl(`
      margin-bottom: 90px;
      padding-bottom:56px;
  `)}
  ${lg(`
    margin-bottom: 60px; 
    padding-bottom:26px;
  `)}
  ${md(`
      margin-bottom: 40px; 
      padding-bottom:10px;
  `)} 
&:after {
      position: absolute;
      content: '';
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f1f1f1); 
      width: 100%;
      height: 676px;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    .heading {
      margin-bottom:38px; 
      ${lg(`
        margin-bottom:30px; 
      `)}
      ${md(`
          margin-bottom:20px; 
      `)} 
    }
  .uiScreenRow {
    display: flex;
    margin: 0 -20px;
    ${md(`
          overflow: auto;
      `)} 
    .uiScreenLeftCol {
      flex: 0 1 50%;
      padding: 0 20px;
      ${md(`
          min-width:250px;
      `)} 
    }
    .uiScreenRightCol {
      flex: 0 1 50%;
      padding: 222px 20px 0;
      ${xl(`
          padding: 200px 20px 0;
      `)}
      ${lg(`
          padding: 150px 20px 0;
      `)}
      ${md(`
          padding: 100px 15px 0;
          min-width:250px;
      `)} 
    }
    .uiScreenHead {
      margin-bottom:30px;
      ${lg(`
           margin-bottom:20px;
        `)} 
      h4{
        padding-top:21px;
        font-size: 15px;
        font-weight: 600;
        color:#3d3d3d;
        line-height:1;
        position: relative;
        margin-bottom:0;
        text-transform:uppercase;
        &::after {
          content:"";
          position:absolute;
          top:0;
          left:0;
          width:55px;
          height:3px;
          background:#3d3d3d;
        }
      }
    }
    .screenList {
      margin-left:-26px;
      ${xl(`
        margin-left:-20px;
      `)}
      ${lg(`
        margin-left:-15px;
      `)}
      ${md(`
         margin-left:-10px;
      `)} 
      .screenListItem {
        margin-bottom:69px;
        ${xl(`
          margin-bottom:60px;
        `)}
        ${lg(`
           margin-bottom:40px;
        `)}
        ${md(`
            margin-bottom:20px;
        `)} 
        &:last-child {
          margin-bottom:0px;
        }
      }
    }
  }
`
