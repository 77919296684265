import React from 'react'
import { SmallImgContentblkStyles } from './SmallImgContentblk.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function SmallImgContentblk(props) {
  const {
    smallImgcontentdata: { smallImgcontentProps },
    showFullimages,
  } = props

  const caseQuoteProp = smallImgcontentProps.caseQuoteProps
  const {
    data: {
      scalefusion: {
        childImageSharp: { fluid },
      },
    },
  } = props
  return (
    <SmallImgContentblkStyles className="smallImgContentblk">
      <div className="container">
        <div className="sectionTitle">
          <h2>{smallImgcontentProps.heading}</h2>
        </div>
        <p>{smallImgcontentProps.para}</p>
        {smallImgcontentProps.bottomPara ? (
          <div className="row">
            {showFullimages && (
              <div className="col imgCol">
                <figure>
                  <Image
                    fluid={fluid}
                    lazyload={{
                      enabled: true,
                      once: true,
                      offset: 1000,
                      heigth: 232,
                    }}
                  />
                </figure>
              </div>
            )}
            <div className="col">
              <p>{smallImgcontentProps.bottomPara}</p>
            </div>
          </div>
        ) : (
          ' '
        )}
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </SmallImgContentblkStyles>
  )
}

export default SmallImgContentblk
