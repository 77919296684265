import styled from 'styled-components'
import { images } from 'config/images'
import { xl, lg, md, sm, bodyFont } from 'config/variables'

export const CaseappadvantagesecStyles = styled.div`
  background: url(${images.ftsAdvantageBg}) no-repeat;
  background-size: cover;
  padding:100px 0;
  margin-bottom: 120px; 
    ${xl(`
        margin-bottom: 90px;
        padding:80px 0;
    `)}
    ${lg(`
      margin-bottom: 60px; 
      padding:60px 0;
    `)}
    ${md(`
        margin-bottom: 40px; 
        padding:40px 0;
    `)} 
  .heading {
    text-align: center;
    margin-bottom:113px; 
    ${lg(`
        margin-bottom:80px; 
    `)}
    ${md(` 
        margin-bottom:70px; 
    `)}
    h2 {
      font-size: 48px;
      line-height:57px;
      letter-spacing:-0.52px;
      color:#931e0a;
      position: relative;
      margin:0px;
      padding-bottom:49px;
      ${xl(`
          font-size: 44px;
          line-height: 53px; 
      `)}
      ${lg(`
          font-size: 44px;
          line-height: 53px; 
          padding-bottom:40px;
      `)}
      ${md(`
          font-size: 42px;
          line-height: 50px; 
      `)}
      ${sm(`
          font-size: 36px;
          line-height: 42px; 
      `)}

      &::after {
        content: '';
        position: absolute;
        left:0;
        bottom:0;
        right:0;
        width:160px;
        height:3px;
        background:#931e0a;
        margin:0 auto;
      }
    }
  }
  .advantageContentRow {
    display:flex; 
    margin: 0 -11px;
    ${lg(`
          margin: 0 -10px;
      `)}
      ${md(` 
          flex-direction:column;
          min-height:auto;
      `)}
    .advantageContentCol {
      flex:0 1 33.33%;
      padding:0 11px;
      ${lg(`
          padding:0 10px;
      `)} 
      ${md(`
          margin-bottom:60px;
      `)} 
      &:last-child {
        ${md(`
          margin-bottom:0;
      `)} 
      }
      .advantageContentBox {
        box-shadow: 4px 4px 18px 10px rgba(0, 0, 0, 0.05);
        background-color: #fff;
        border-radius: 17px;
        height:100%;
        padding:88px 31px 43px 32px;
        max-width:417px;
        margin:0 auto; 
        position: relative;
        min-height:510px;
        ${lg(`
           padding:60px 20px 20px;
        `)}
        ${md(` 
           min-height:auto;
        `)}
        .iconThumb{
          text-align: center;
          position: absolute;
          top: -66px;
          left: 0;
          right: 0;
          height: 132px;
          ${lg(`
              height: 100px;
              top:-50px;
          `)}
          img {
            ${lg(`
                width:100px;
            `)} 
          }
        }
        h4 {
          font-size: 25px;
          line-height:32px;
          font-weight: 600;
          color:#931e0a;
          font-family: ${bodyFont}
          ${lg(`
              font-size: 22px;
              line-height:28px;
          `)}
        }
        p {
          margin-bottom:0px;
          ${lg(`
              font-size: 18px;
              line-height:26px;
          `)}
        }
      }
    }
  }
`
